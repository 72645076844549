// extracted by mini-css-extract-plugin
export var mainNav = "header-module--main-nav---ybkQ";
export var mainHeader = "header-module--main-header--YIblt";
export var small = "header-module--small--PW94B";
export var logoMark = "header-module--logo-mark--FCoVg";
export var logoType = "header-module--logo-type--g+vrc";
export var headerCenter = "header-module--header-center--aw+yx";
export var headerRight = "header-module--header-right--JyuGl";
export var highlighted = "header-module--highlighted--Qc6Nd";
export var headerLower = "header-module--header-lower--xlbo4";
export var logo = "header-module--logo--mTLiM";